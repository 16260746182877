.react-datepicker-wrapper {
    width: 100%;
}

.date-picker {
    background-color: red;
    border: none;
    line-height: 1em;
    transition: 0.3s;
    background-color: transparent;
    border-radius: 4px;
    padding: 0em 0.5em;
    font-family: lato;
    height: 36px;
    flex-grow: 1;
        
    &:focus {
        border-color: #ce0069;
        outline: none;
    }
}

.react-datepicker__close-icon:after {
    background-color: #aaa;
    height: 12px;
    width: 12px;
}