.marker {
    background-image: url('./../assets/map_marker.png');
    width: 30px;
    height: 30px;
    background-size: contain;

    &:hover {
        opacity: 1.0;
    }

    &.active {
        opacity: 1.0;
        z-index: 10;
    }

    &.primary {
        opacity: 1.0;
        z-index: 20;
    }

    &.secondary {
        opacity: 1.0;
        z-index: 10;
    }
}

.employee-marker {
    background-image: url('./../assets/map_marker_employee.png');
    height: 40px;
    opacity: 0.5;

    &.active {
        background-image: url('./../assets/map_marker_employee_marked.png');
    }
}

.customer-marker {
    background-image: url('./../assets/map_marker_customer.png');
    height: 40px;
    opacity: 0.5;

    &.active {
        background-image: url('./../assets/map_marker_customer_marked.png');
    }
}

.mapboxgl-popup-content {
    padding: 0;
    box-shadow: 0 1px 8px 1px rgba(0,0,0,0.4);
}

.car-icon {
    border-bottom: 3px solid #ce0069;
    padding: 0.1em 0;
}

.walk-icon {
    border-bottom: 3px solid #00aa00;
    padding: 0.1em 0;
}